
import { DexAssessmentData } from "@/domain/entities/DexAssessment";
import { Options, prop, Vue } from "vue-class-component";
import PreviewImage from "@/app/ui/views/shipment/tracking-stt-cargo/tracking-stt-tab/component/preview-image-pod.vue";

class Props {
  data = prop<any>({
    default: new DexAssessmentData(),
  });
}
@Options({
  components: { PreviewImage },
})
export default class DisputePartnerInformationSection extends Vue.with(Props) {
  showPreviewImage = false;
  actionModalPreview(boolean: boolean) {
    this.showPreviewImage = boolean;
  }
}
